<template>
  <transition name="modal">
    <div
      class="modal-mask"
      @click="$emit('close')"
      :style="{backgroundImage : customStyle.backgroundImage}"
    >
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop :style="customStyle">
          <div class="modal-header">
            <slot name="header">{{title}}</slot>
            <slot v-if="!this.$parent.$parent.$data.isMobile" name="icon"></slot>
          </div>

          <div class="modal-body">
            <slot name="body">{{copy}}</slot>
            <slot v-if="this.$parent.$parent.$data.isMobile" name="icon"></slot>
          </div>

          <button class="modal-close-button" @click="$emit('close')" :style="customStyle">
            <svg
              id="close_button"
              data-name="Close button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 25.81 25.81"
            >
              <title>Close</title>
              <line
                x1="2.55"
                y1="23.25"
                x2="23.25"
                y2="2.55"
                :style="{stroke : customStyle.stroke}"
                class="line"
              ></line>
              <line
                x1="23.25"
                y1="23.25"
                x2="2.55"
                y2="2.55"
                :style="{stroke : customStyle.stroke}"
                class="line"
              ></line>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: ["title", "copy", "customStyle", "iconBeneficios"]
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: table;
  transition: opacity 0.3s ease;
  font-family: "Gotham-Bold";

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0px auto;
  padding: 1rem 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @media (min-width: 480px) {
    max-width: 60vmax;
    max-height: 70vmin;
    border: 6px solid var(--black);
  }

  background-color: var(--white);
  border: none;
  background-image: none !important;

  transition: all 0.3s ease;
}

.modal-header {
  font-family: "Gotham-Ultra";
  font-size: 2.6vmax;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: auto 20%;
  justify-content: stretch;
  align-items: start;
  width: 100%;

  @media (min-width: 480px) {
    font-size: 2.2vmax;
    grid-template-columns: auto 10%;
  }
}

.modal-body {
  font-size: 2vmax;
  @media (min-width: 480px) {
    font-size: 1.2vmax;
  }
}

.modal-close-button {
  position: absolute;
  top: -6px;
  right: -6px;
  border: 6px solid var(--black);
  background: transparent;
  margin: 0;
  font-family: "Gotham-Bold";
  font-size: 2vmax;
  text-transform: uppercase;
  cursor: pointer;
  background-image: none !important;
  padding: 8px 10px;
  line-height: 1;

  svg {
    width: 20px;
  }

  .line {
    stroke-miterlimit: 10;
    stroke-width: 7.22092982461287px;
    stroke: var(--black);
  }

  @media (min-width: 480px) {
    top: -6px;
    right: -6px;
  }
}

#Beneficios {
  .modal-container {
    overflow: hidden;
  }

  .modal-close-button {
    top: initial;
    bottom: -6px;
  }
}

// ANIMATION -----------------

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
