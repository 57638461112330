<template>
  <section id="Beneficios">
    <div class="beneficio-item" @click="openModal(0)">
      <h3 class="title">Estética
        <br>y bienestar
      </h3>
    </div>
    <div class="beneficio-item empty">
      <h2 class="title main-title">BENE-
        <br>FICIOS
        <br>
        <span>DIAR</span>
      </h2>
    </div>
    <div class="beneficio-item" @click="openModal(1)">
      <h3 class="title">Corazón</h3>
    </div>
    <div class="beneficio-item" @click="openModal(2)">
      <h3 class="title">Cerebro</h3>
    </div>
    <div class="beneficio-item" @click="openModal(3)">
      <h3 class="title">Visión</h3>
    </div>
    <div class="beneficio-item empty"></div>
    <div class="beneficio-item empty"></div>
    <div class="beneficio-item" @click="openModal(4)">
      <h3 class="title">Desarrollo
        <br>infantil
      </h3>
    </div>
    <div class="beneficio-item" @click="openModal(5)">
      <h3 class="title">Respuesta
        <br>inflamatoria
      </h3>
    </div>
    <div class="beneficio-item" @click="openModal(6)">
      <h3 class="title">Rendi-
        <br>miento
        <br>deportivo
      </h3>
    </div>
    <div class="beneficio-item" @click="openModal(7)">
      <h3 class="title">Calidad
        <br>del
        <br>sueño
      </h3>
    </div>
    <div class="beneficio-item" @click="openModal(8)">
      <h3 class="title">Sistema
        <br>inmunológico
      </h3>
    </div>

    <modal v-if="showModal" @close="showModal = false" :customStyle="modal.customStyle">
      <h3 slot="header" v-html="modal.title"></h3>
      <p slot="body" v-html="modal.copy"></p>
      <img slot="icon" :src="modal.iconBeneficios" alt class="icon-beneficios">
    </modal>
  </section>
</template>

<script>
import Modal from "../components/Modal";

export default {
  name: "Beneficios",
  components: {
    Modal
  },
  data() {
    return {
      showModal: false,
      modal: {
        title: "",
        copy: "",
        customStyle: {},
        iconBeneficios: ""
      },
      modal_data: [
        {
          title: `
            ESTÉTICA
            <br>
            Y BIENESTAR`,
          copy: `
            . Ayuda a disminuir la sequedad de la piel<br>
            . Aumenta la hidratación y elasticidad de la piel<br>
            . Disminuye la descamación<br>
            . Mejora la apariencia de uñas<br>
            . Disminuye la caída del cabello<br>
            . Reduce el envejecimiento celular por exposición al sol.<br>
            . Reduce las lesiones provocadas por el acné.<br>
          `,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderColor: "var(--black)",
            backgroundImage: "url('img/beneficios-01-bg.png')"
          },
          iconBeneficios: "img/beneficios-01-icon.png"
        },
        {
          title: `CORAZÓN`,
          copy: `
            Las enfermedades cardiovasculares representan la primera causa de mortalidad a nivel mundial. Es fundamental la prevención.
            <br><br>
            . Reduce el colesterol total en sangre<br>
            . Reduce el LDL (colesterol “malo”)<br>
            . Aumenta del HDL (colesterol “bueno”)<br>
            . Reduce los triglicéridos en sangre<br>
            . Reduce la hipertensión arterial<br>
            . Reduce la formación de placas en las arterias <br>
            . Ayuda a prevenir arritmias <br>
            . Previene la formación de trombos
          `,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderColor: "var(--black)",
            backgroundImage: "linear-gradient(var(--red),var(--red))"
          },
          iconBeneficios: "img/beneficios-03-icon.png"
        },
        {
          title: `CEREBRO`,
          copy: `
            El ácido graso DHA es neuroprotector, y resulta fundamental en el mantenimiento de las funciones cognitivas (memoria y rendimiento mental) y en la prevención de trastornos del comportamiento.
            <br><br>
            . Mantiene la funcionalidad de las membranas neuronales<br>
            . Mejora la memoria<br>
            . Mejora la capacidad cognitiva<br>
            . Disminuye la muerte de neuronas<br>
            . Previene trastornos del comportamiento<br>
            . Ayuda a prevenir enfermedades invalidantes neurodegenerativas como Alzheimer y Parkinson.<br>
            . Ayuda a mejorar el trastorno bipolar.<br>
            . Reduce los síntomas emocionales relacionados con el estrés
          `,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderColor: "var(--black)",
            backgroundImage: "linear-gradient(var(--white),var(--white))"
          },
          iconBeneficios: "img/beneficios-04-icon.png"
        },
        {
          title: `VISIÓN`,
          copy: `
            . Mejora la secreción de las lágrimas naturales<br>
            . Disminuye los síntomas de ojo seco<br>
            . Previene el daño en la retina dado por la exposición a los rayos UV<br>
            . Actúa como neuroprotector<br>
            . Aumentan el pigmento ocular que filtra la luz azul.
          `,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--white)",
            borderColor: "var(--red)",
            backgroundImage: "linear-gradient(var(--white),var(--white))",
            stroke: "var(--red)"
          },
          iconBeneficios: "img/beneficios-05-icon.png"
        },
        {
          title: `
            DESARROLLO
            <br>
            INFANTIL`,
          copy: `
            La deficiencia de DHA en la madre durante el período fetal y neonatal se ha asociado a problemas de aprendizaje y comportamiento. También se ha asociado a un aumento del riesgo del parto prematuro.
            <br><br>
            . Influye positivamente en el desarrollo cerebral del feto<br>
            . Ayuda a prevenir partos prematuros<br>
            . Ayuda a prevenir la depresión postparto materna.
          `,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderColor: "var(--black)",
            backgroundImage: "url('img/beneficios-08-bg.jpg')"
          },
          iconBeneficios: "img/beneficios-08-icon.png"
        },
        {
          title: `
            RESPUESTA
            <br>
            INFLAMATORIA`,
          copy: `
            Los ácidos grasos Omega-3 actúan como moduladores de la respuesta inflamatoria.
            <br><br>
            . Alivia los síntomas premenstruales<br>
            . Alivia los síntomas de artritis
          `,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderColor: "var(--black)",
            backgroundImage: "linear-gradient(var(--red),var(--red))"
          },
          iconBeneficios: "img/beneficios-09-icon.png"
        },
        {
          title: `
            RENDIMIENTO
            <br>
            DEPORTIVO`,
          copy: `
            . Ayuda a optimizar el rendimiento físico<br>
            . Aumenta el rendimiento muscular<br>
            . Ayuda a una recuperación muscular más veloz<br>
            . Ayuda a reducir la fatiga física <br>
            . Aumenta la resistencia física.
          `,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderColor: "var(--black)",
            backgroundImage: "url('img/beneficios-10-bg.jpg')"
          },
          iconBeneficios: "img/beneficios-10-icon.png"
        },
        {
          title: `
          CALIDAD
          <br>
          DEL SUEÑO`,
          copy: `
            Los niveles altos de DHA en sangre 
            <br><br>
            . Mejoran la calidad del sueño<br>
            . Reducen el insomnio
          `,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--white)",
            borderColor: "var(--red)",
            backgroundImage: "linear-gradient(var(--black),var(--black))",
            stroke: "var(--red)"
          },
          iconBeneficios: "img/beneficios-11-icon.png"
        },
        {
          title: `
          SISTEMA
          <br>
          INMUNOLÓGICO`,
          copy: `
            Los ácidos grasos Omega-3 contribuyen a mejorar la función inmune
            <br><br>
            . Disminuye el riesgo de infecciones
          `,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--white)",
            borderColor: "var(--red)",
            backgroundImage: "linear-gradient(var(--white),var(--white))",
            stroke: "var(--red)"
          },
          iconBeneficios: "img/beneficios-12-icon.png"
        }
      ]
    };
  },
  methods: {
    openModal(id) {
      this.modal.title = this.modal_data[id].title;
      this.modal.copy = this.modal_data[id].copy;
      this.modal.iconBeneficios = this.modal_data[id].iconBeneficios;
      this.modal.customStyle = this.modal_data[id].customStyle;
      this.showModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  font-family: "Gotham-Bold";

  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(6, 1fr);

  @media (min-width: 480px) {
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: repeat(3, 33.33%);
  }

  .beneficio-item {
    background-color: var(--white);
    padding: calc(var(--padding-sections));

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: grid;
    justify-content: start;
    align-content: start;

    height: 100%;

    @media (min-width: 480px) {
      padding-top: calc(var(--padding-sections) * 2);
      padding-bottom: calc(var(--padding-sections) * 2);
    }

    &:not(.empty) {
      cursor: pointer;
    }

    &:nth-child(1) {
      background-image: url("../assets/beneficios-01-bg.png");
      .title {
        color: var(--black);
      }
    }

    &:nth-child(2) {
      background-color: var(--black);
      .title {
        color: var(--white);
        span {
          color: var(--red);
        }
      }
    }

    &:nth-child(3) {
      background-color: var(--red);
      .title {
        color: var(--black);
      }
    }

    &:nth-child(4) {
      background-color: var(--white);
      .title {
        color: var(--black);
      }
    }

    &:nth-child(5) {
      background-color: var(--white);
      .title {
        color: var(--red);
      }
    }

    &:nth-child(6) {
      background-color: var(--black);
      @media (min-width: 480px) {
        grid-column: 1;
        grid-row: 2;
      }
    }

    &:nth-child(7) {
      background-image: url("../assets/beneficios-07-bg.jpg");
      .title {
        color: var(--red);
      }
    }

    &:nth-child(8) {
      background-image: url("../assets/beneficios-08-bg.jpg");
      .title {
        color: var(--white);
      }
    }

    &:nth-child(9) {
      background-color: var(--red);
      .title {
        color: var(--white);
      }
    }

    &:nth-child(10) {
      background-image: url("../assets/beneficios-10-bg.jpg");
      .title {
        color: var(--white);
      }
    }

    &:nth-child(11) {
      background-color: var(--black);
      .title {
        color: var(--red);
      }
    }

    &:nth-child(12) {
      background-color: var(--white);
      .title {
        color: var(--red);
      }
    }

    .title {
      height: 100%;
      font-size: 2.4vmax;

      @media (min-width: 480px) {
        font-size: 1.8vmax;
      }

      &.main-title {
        font-family: "Gotham-Ultra";
        font-size: 3.3vmax;
        line-height: 1;

        @media (min-width: 480px) {
          font-size: 3vmax;
        }
      }
    }
  }
}

.modal-header {
  .icon-beneficios {
    padding-left: 2rem;
  }
}
.icon-beneficios {
  @media (max-width: 480px) {
    width: 15%;
  }
}
</style>
