<template>
  <div id="app">
    <MenuDesktop v-if="!isMobile"/>
    <MenuMobile v-else width="100"/>

    <Home/>
    <Diar/>
    <DiarCaracteristicas/>
    <Krill/>
    <Chia/>
    <Beneficios/>
    <Contacto/>
  </div>
</template>

<script>
import Home from "./components/Home";
import Diar from "./components/Diar";
import DiarCaracteristicas from "./components/DiarCaracteristicas";
import Krill from "./components/Krill";
import Chia from "./components/Chia";
import Beneficios from "./components/Beneficios";
import Contacto from "./components/Contacto";

import MenuDesktop from "./components/MenuDesktop";
import MenuMobile from "./components/MenuMobile";

export default {
  name: "app",
  components: {
    Home,
    Diar,
    DiarCaracteristicas,
    Krill,
    Chia,
    Beneficios,
    Contacto,
    MenuDesktop,
    MenuMobile
  },
  data() {
    return {
      isMobile: false
    };
  },
  beforeMount: function() {
    if (window.innerWidth <= 480) {
      this.isMobile = true;
    }

    // fix for mobile vh -----------------------
    // let vh = window.innerHeight * 0.01;
    // document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
};
</script>

<style lang="scss">
@import "../node_modules/normalize.css/normalize.css";
@import "./assets/scss/_fonts.scss";

// RESETS -----------
* {
  box-sizing: border-box;
  transition: all 600ms ease-in-out;
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3 {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

// VARIABLES -----------
:root {
  --black: #333333;
  --white: #ffffff;
  --red: #ff4338;
  --grey: #cacaca;

  --padding-sections: 3vmax;
}

// SWAL --------------
.swal2-container {
  font-family: "Gotham-Bold";

  button {
    background-color: var(--red) !important;
    border: none;
  }
}

// FIX FOR MODAL OPENED -------------
body {
  &.modal-open {
    overflow: hidden;
  }
}

// FIX FOR MENU OPENED -------------
body {
  &.bm-overlay {
    overflow: hidden;

    .bm-menu {
      width: 100% !important;
    }

    .bm-cross-button {
      top: 1rem !important;
      left: 1rem !important;
      right: initial !important;
    }
  }
}

// BURGUER MENU -----------
.bm-burger-button {
  z-index: 999;
  position: fixed !important;
  width: 15px !important;
  height: 12px !important;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 3px solid var(--red);
    border-radius: 50%;
  }

  .bm-burger-bars {
    background-color: var(--red);
    height: 20%;
  }
}

.bm-menu {
  background-color: var(--red) !important;
  font-family: "Gotham-Bold" !important;
  color: var(--black) !important;

  img {
    width: 1.4rem;
  }

  .bm-cross {
    background: var(--white);
  }

  .bm-item-list > * > span {
    color: var(--black);
  }

  .bm-item-list > * {
    align-items: center;
  }
}
</style>
