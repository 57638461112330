import { render, staticRenderFns } from "./Diar.vue?vue&type=template&id=855905d6&scoped=true&"
import script from "./Diar.vue?vue&type=script&lang=js&"
export * from "./Diar.vue?vue&type=script&lang=js&"
import style0 from "./Diar.vue?vue&type=style&index=0&id=855905d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855905d6",
  null
  
)

export default component.exports