import { render, staticRenderFns } from "./DiarCaracteristicas.vue?vue&type=template&id=e43bc330&scoped=true&"
import script from "./DiarCaracteristicas.vue?vue&type=script&lang=js&"
export * from "./DiarCaracteristicas.vue?vue&type=script&lang=js&"
import style0 from "./DiarCaracteristicas.vue?vue&type=style&index=0&id=e43bc330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e43bc330",
  null
  
)

export default component.exports