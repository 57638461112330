import Vue from "vue";
import App from "./App.vue";
import smoothscroll from "smoothscroll-polyfill";
import ScrollSnap from "scroll-snap";

const snapConfig = {
  scrollSnapDestination: "90% 0%", // *REQUIRED* scroll-snap-destination css property, as defined here: https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-snap-destination
  scrollTimeout: 100, // *OPTIONAL* (default = 100) time in ms after which scrolling is considered finished
  scrollTime: 300 // *OPTIONAL* (default = 300) time in ms for the smooth snap
};

const element = document.getElementById("app");
const snapObject = new ScrollSnap(element, snapConfig);

snapObject.bind();
smoothscroll.polyfill();

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
