<template>
  <section id="Home">
    <div class="content">
      <h1 class="title">La evolución
        <br>del Omega 3
      </h1>
      <p class="subtitle">Suplemento dietario
        <br>a base de aceite de krill
      </p>
      <img class="diar-pack" src="../assets/diar-pack.png" alt="Diar pack">
    </div>
    <div class="diar-logo">
      <img src="../assets/diar-logo.png" alt="Diar Logo">
    </div>
    <div class="poen-logo">
      <img src="../assets/poen-logo.png" alt="Poen Logo">
    </div>
    <div class="scroll">
      <img src="../assets/scroll.png" alt="scroll arrow">
    </div>
  </section>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss" scoped>
section {
  background-color: #f7f7f7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/home-background-mobile.png");

  height: 100vh;
  position: relative;

  display: grid;
  align-items: end;
  justify-content: start;
  padding: var(--padding-sections);

  color: var(--black);

  @media (min-width: 480px) {
    background-image: url("../assets/home-background.png");
    padding: 0 10vmax;

    align-items: end;
    justify-content: center;
    padding-bottom: 5vmin;
  }

  .content {
    .title {
      font-family: "Gotham-Ultra";
      font-size: 5vmax;
      position: relative;
      text-transform: uppercase;
      &:before {
        content: "";
        position: absolute;
        height: 2px;
        background: var(--black);
        z-index: 1;
        top: -1rem;
        left: 0;
        display: block;
        width: 40%;
      }

      @media (min-width: 480px) {
        font-size: 4vmax;
      }
    }
    .subtitle {
      font-family: "Gotham-Bold";
      font-size: 2.5vmax;
      @media (min-width: 480px) {
        font-size: 2vmax;
      }
    }
    .diar-pack {
      max-width: 40vmin;
    }
  }

  .diar-logo {
    position: absolute;
    top: 1rem;
    left: 1rem;
    @media (min-width: 480px) {
      left: 12vw;
    }
    max-width: 15vmax;
  }

  .poen-logo {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    max-width: 10vmax;
    @media (min-width: 480px) {
      right: 12vw;
    }
  }

  .scroll {
    display: none;
    width: 16px;
    height: 81px;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    animation: scroll 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 3000ms
      infinite alternate;
    @media (min-width: 480px) {
      display: block;
      left: 12vw;
    }
  }
}

// ANIMACION SCROLL -------------
@keyframes scroll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(5%);
  }
}
</style>
