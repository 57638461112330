<template>
  <section id="Diar">
    <div class="content">
      <h2 class="title">DIAR</h2>
      <div class="copy">
        <p>Es un suplemento para una nutrición inteligente a base de aceite de krill 100% puro y natural provisto en pequeñas cápsulas blandas.</p>
        <p>
          Diar es Omega 3 premium ya que aporta además de
          <span @click="openModal(0)">Omega 3</span>,
          <span @click="openModal(1)">fosfolípidos</span>,
          <span @click="openModal(3)">astaxantina</span> y
          <span @click="openModal(2)">colina</span>.
        </p>
        <p>No tiene gusto a pescado, no genera reflujo gástrico, no contiene grasas trans, ni metales pesados, ni otros contaminantes.</p>
        <p>Diar es la evolución del Omega 3.</p>
      </div>
      <div class="capsula">
        <img src="../assets/Diar_AnimacionPastilla.gif" alt="Diar Animación Pastilla">
      </div>

      <div class="cruz">
        <img src="../assets/cruz.png" alt>
      </div>

      <div class="linea">
        <img src="../assets/linea.png" alt>
      </div>

      <modal v-if="showModal" @close="showModal = false" :customStyle="modal.customStyle">
        <h3 slot="header" v-html="modal.title"></h3>
        <p slot="body" v-html="modal.copy"></p>
      </modal>
    </div>
  </section>
</template>

<script>
import Modal from "../components/Modal";

export default {
  name: "Diar",
  components: {
    Modal
  },
  data() {
    return {
      showModal: false,
      modal: {
        title: "",
        copy: "",
        customStyle: {}
      },
      modal_data: [
        {
          title: "OMEGA<br>3",
          copy: `Los ácidos grasos Omega-3 son considerados grasas saludables para nuestro organismo. Son indispensables para la vida humana ya que, además de constituir una fuente energética, forman parte de la estructura de las membranas de todas nuestras células (incluyendo las neuronas) y participan de numerosos procesos metabólicos. 
          <br>
          <br>
          Resulta imprescindible incorporar ácidos grasos Omega-3 EPA y DHA a nuestras dietas para ayudar a mantener un cuerpo saludable.<br>Se los denomina “esenciales” porque el cuerpo humano no los produce por sí solo, por lo cual hay que incorporarlos con la ingesta diaria, ya sea incluyendo mucho pescado y frutos secos, o a través de la incorporación de suplementos nutricionales.<br>Los ácidos grasos Omega 3 (principalmente EPA y DHA) intervienen en la prevención de muchas enfermedades, por lo que su incorporación a la dieta otorga innumerables beneficios a nuestra salud cardiovascular ya que ayudan a regular el colesterol total, HDL, LDL y triglicéridos, hasta a nivel cerebral, ya que el DHA es el ácido graso Omega-3 más abundante en las membranas de nuestras neuronas. Además, brindan beneficios a nivel de la salud ocular, muscular, estética y bienestar general (piel, uñas, pelo) y durante el embarazo en el desarrollo cerebral del feto.`,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--white)",
            borderColor: "var(--red)",
            stroke: "var(--red)"
          }
        },
        {
          title: "Fosfo-<br>lípidos",
          copy: `Tienen muchas funciones en el cuerpo. Son de extrema importancia para el funcionamiento normal de las membranas celulares, le otorgan fluidez para poder incorporar nutrientes (como el EPA y el DHA) a cada célula, participan del metabolismo energético, ayudan a la coagulación de la sangre, tienen acción antioxidante y contribuyen a la solubilidad del colesterol, entre otros beneficios.
          <br>
          <br>
          Además actúan como agentes humectantes superficiales, activo que recubre el exterior del hígado, los pulmones, el tracto gastrointestinal y las células renales.
          <br>
          <br>
          Es importante que todas las células de nuestro cuerpo mantengan una suficiente cantidad de fosfolípidos para garantizar su óptimo funcionamiento.`,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--red)",
            borderColor: "var(--black)"
          }
        },
        {
          title: "COLINA",
          copy: `La colina es un nutriente esencial similar a una vitamina, fundamental para la función normal de las células. Nuestro cuerpo produce cantidades mínimas de colina por lo cual hay que suministrarla a través de la dieta. 
          <br>
          <br>
          Al igual que el DHA, la colina es importante para el desarrollo cerebral. Durante el período de gestación, es imprescindible para el desarrollo del cerebro y la memoria del feto y para reducir el riesgo de defectos en el tubo neural.
          <br>
          <br>
          En el sistema nervioso, la colina se convierte en acetilcolina que es importante para el aprendizaje, la respiración, la memoria, el sueño y el metabolismo muscular.`,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--black)",
            borderColor: "var(--red)",
            stroke: "var(--red)"
          }
        },
        {
          title: "Asta-<br>xantina",
          copy: `
          Es un potente antioxidante y es el responsable del color rojo del aceite de krill.
          <br><br>
          La función de los antioxidantes en nuestro organismo es contrarrestar los efectos perjudiciales de los denominados radicales libres. Estas moléculas son las causantes del estrés oxidativo contribuyendo al envejecimiento celular y a diversos problemas crónicos de salud.`,
          customStyle: {
            color: "var(--white)",
            backgroundColor: "var(--red)",
            borderColor: "var(--white)",
            stroke: "var(--white)"
          }
        }
      ]
    };
  },
  methods: {
    openModal(id) {
      this.modal.title = this.modal_data[id].title;
      this.modal.copy = this.modal_data[id].copy;
      this.modal.customStyle = this.modal_data[id].customStyle;
      this.showModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  height: 100vh;
  background-color: #333333;
  padding: calc(var(--padding-sections) * 3);
  color: var(--white);
  font-family: "Gotham-Bold";

  display: grid;
  align-items: center;
  justify-content: center;

  @media (min-width: 480px) {
    padding: calc(var(--padding-sections)) 30vmax;
    min-height: 100vh;
    height: auto;
  }

  .content {
    position: relative;

    .title {
      font-family: "Gotham-Ultra";
      color: var(--red);
      font-size: 7vmax;
      @media (min-width: 480px) {
        font-size: 4vmax;
      }
    }

    .copy {
      font-size: 2vmax;
      line-height: 1.5;

      @media (min-width: 480px) {
        font-size: 1.2vmax;
      }

      span {
        background-color: var(--red);
        cursor: pointer;
      }
    }

    .cruz {
      position: absolute;
      top: -2rem;
      left: -2.6rem;
      width: 50%;
    }

    .linea {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
