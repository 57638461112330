<template>
  <section id="Krill">
    <article id="El-Krill">
      <div class="content">
        <h2 class="title">El Krill</h2>
        <div class="copy">
          <p>Es de aspecto similar a los camarones y su tamaño es menor al de un dedo meñique.</p>
          <p>Su hábitat natural es el Océano Antártico y se alimenta principalmente de fitoplancton.</p>
          <p>El krill es capaz de sobrevivir al clima hostil antártico gracias a que posee una gran cantidad de ácidos grasos poliinsaturados Omega-3, principalmente el ácido graso (EPA) y el ácido graso (DHA) asociados a sus membranas celulares. El aceite de krill es una fuente pura y natural de ácidos grasos Omega-3, fosfolípidos, colina y astaxantina. Esta combinación de nutrientes lo convierte en una sustancia única.</p>
          <p>El krill es la mejor fuente de Omega 3.</p>
        </div>
      </div>
      <div class="mas-info" @click="viewArticle">+
        <br>Info
      </div>
    </article>

    <article id="Pesca-Sustentable" class="inactive">
      <div class="content">
        <h2 class="title" v-if="breakTitle">PESCA
          <br>SUSTEN-
          <br>TABLE
        </h2>
        <h2 class="title" v-else>PESCA SUSTENTABLE</h2>
        <div class="copy">
          <p>El tratado internacional CCAMLR (Convención para la Conservación de los Recursos Vivos Marinos Antárticos) regula la pesca de krill de forma sustentable.</p>
          <p>El área 48 del Océano Sur es la única área donde está permitida la pesca de krill, y se autoriza la pesca de solo el 1% de la biomasa estimada al día de hoy.</p>
        </div>
      </div>
      <div class="mas-info" @click="viewArticle">+
        <br>Info
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: "Krill",
  data() {
    return {
      breakTitle: true
    };
  },
  methods: {
    viewArticle(e) {
      document
        .querySelector("article:not(.inactive)")
        .classList.toggle("inactive");

      setTimeout(() => {
        e.target.parentElement.classList.toggle("inactive");

        let activo = e.target.parentElement.id;

        activo === "Pesca-Sustentable"
          ? (this.breakTitle = false)
          : (this.breakTitle = true);
      }, 600);
    }
  },
  mounted() {
    this.$parent.isMobile
      ? (this.breakTitle = false)
      : (this.breakTitle = true);
  }
};
</script>

<style lang="scss" scoped>
section {
  background-color: var(--grey);
  @media (min-width: 480px) {
    position: relative;
    height: 100vh;
  }
}

article {
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background-color: var(--black);
  padding: calc(var(--padding-sections) * 3);
  font-family: "Gotham-Bold";

  display: grid;
  justify-content: center;
  align-content: center;

  overflow: hidden;

  @media (min-width: 480px) {
    position: absolute;
    padding: calc(var(--padding-sections)) 30vmax;

    &.inactive {
      z-index: 10;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20vmax;
      height: 20vmax;
      min-height: auto;
      padding: var(--padding-sections);

      .content {
        &:before,
        &:after {
          opacity: 0;
        }
      }

      .title {
        font-size: 2vmax;
      }

      .copy {
        transform: translateY(200%);
        opacity: 0;
        position: absolute;
      }

      .mas-info {
        height: auto;
        opacity: 1;
        text-transform: uppercase;
      }
    }
  }

  .content {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      background-color: var(--white);
      top: 0;
      left: -1.5rem;
      height: 100vh;

      @media (min-width: 480px) {
        height: 100%;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: var(--white);
      top: calc(0% + 0.5rem);
      left: calc(-1.5rem - 0.5rem);
    }
  }

  .title {
    font-family: "Gotham-Ultra";
    font-size: 5vmax;
    text-transform: uppercase;
    @media (min-width: 480px) {
      font-size: 4vmax;
    }
  }

  .copy {
    font-size: 2vmax;
    line-height: 1.3;

    @media (min-width: 480px) {
      font-size: 1.3vmax;
    }
  }

  .mas-info {
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  &#El-Krill {
    background-color: var(--grey);

    .content {
      &:before {
        background-color: var(--red);
      }
      &:after {
        background-color: var(--red);
      }
    }

    .title {
      color: var(--red);
    }

    .copy {
      color: var(--black);
    }
  }

  &#Pesca-Sustentable {
    background-image: url("../assets/pesca-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 15% center;
    color: var(--white);

    .content {
      &:before {
        top: calc((var(--padding-sections) * 3) * -1);
      }
    }

    @media (min-width: 480px) {
      background-position: left center;
      .content {
        &:before {
          top: unset;
        }
      }
    }

    .title {
      color: var(--white);
    }
  }
}
</style>
