<template>
  <nav>
    <ul>
      <li>
        <a href="#Diar" @click="smoothScroll('#Diar')">
          <img src="../assets/icon_diar_red.png" alt="Diar">
          <span>DIAR</span>
        </a>
      </li>
      <li>
        <a href="#El-Krill" @click="smoothScroll('#El-Krill')">
          <img src="../assets/icon_krill_red.png" alt="El Krill">
          <span>EL Krill</span>
        </a>
      </li>
      <li>
        <a href="#Chia" @click="smoothScroll('#Chia')">
          <img src="../assets/icon_chia_red.png" alt="Chia">
          <span>Chía, pescado y krill</span>
        </a>
      </li>
      <li>
        <a href="#Beneficios" @click="smoothScroll('#Beneficios')">
          <img src="../assets/icon_beneficios_red.png" alt="Beneficios">
          <span>Beneficios</span>
        </a>
      </li>
      <li>
        <a href="#Contacto" @click="smoothScroll('#Contacto')">
          <img src="../assets/icon_contacto_red.png" alt="Contacto">
          <span>Contacto</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MenuDesktop",
  methods: {
    smoothScroll(element) {
      document.querySelector(element).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 8vw;
  z-index: 99;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 1rem auto;
      position: relative;
      color: var(--red);
      font-family: "Gotham-Bold";
      font-size: 1.4vmax;

      a {
        color: unset;
      }

      &:hover {
        span {
          left: 120%;
          opacity: 1;
        }
      }

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -1000%;
        opacity: 0;
        transition: all 300ms ease-in-out;
        white-space: nowrap;
      }
    }
  }
}
</style>
