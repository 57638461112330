<template>
  <section id="Diar-Caracteristicas">
    <div class="caracteristica-item imagenA empty">
      <picture>
        <source
          media="(min-width: 480px)"
          srcset="../assets/diar-caracteristicas-imagenA-desktop.png"
        >
        <img src="../assets/diar-caracteristicas-imagenA.png" alt>
      </picture>
    </div>
    <div class="caracteristica-item omega" @click="openModal(0)">
      <div class="title">Omega
        <br>3
      </div>
    </div>
    <div class="caracteristica-item fosfolipidos" @click="openModal(1)">
      <div class="title">Fosfo-
        <br>lípidos
      </div>
    </div>
    <div class="caracteristica-item colina" @click="openModal(2)">
      <div class="title">Colina</div>
    </div>
    <div class="caracteristica-item astaxantina" @click="openModal(3)">
      <div class="title">Asta-
        <br>Xantina
      </div>
    </div>
    <div class="caracteristica-item imagenB empty">
      <picture>
        <source
          media="(min-width: 480px)"
          srcset="../assets/diar-caracteristicas-imagenB-desktop.png"
        >
        <img src="../assets/diar-caracteristicas-imagenB.png" alt>
      </picture>
    </div>

    <modal v-if="showModal" @close="showModal = false" :customStyle="modal.customStyle">
      <h3 slot="header" v-html="modal.title"></h3>
      <p slot="body" v-html="modal.copy"></p>
    </modal>
  </section>
</template>

<script>
import Modal from "../components/Modal";

export default {
  name: "DiarCaracteristicas",
  components: {
    Modal
  },
  data() {
    return {
      showModal: false,
      modal: {
        title: "",
        copy: "",
        customStyle: {}
      },
      modal_data: [
        {
          title: "OMEGA<br>3",
          copy: `Los ácidos grasos Omega-3 son considerados grasas saludables para nuestro organismo. Son indispensables para la vida humana ya que, además de constituir una fuente energética, forman parte de la estructura de las membranas de todas nuestras células (incluyendo las neuronas) y participan de numerosos procesos metabólicos. 
          <br>
          <br>
          Resulta imprescindible incorporar ácidos grasos Omega-3 EPA y DHA a nuestras dietas para ayudar a mantener un cuerpo saludable.<br>Se los denomina “esenciales” porque el cuerpo humano no los produce por sí solo, por lo cual hay que incorporarlos con la ingesta diaria, ya sea incluyendo mucho pescado y frutos secos, o a través de la incorporación de suplementos nutricionales.<br>Los ácidos grasos Omega 3 (principalmente EPA y DHA) intervienen en la prevención de muchas enfermedades, por lo que su incorporación a la dieta otorga innumerables beneficios a nuestra salud cardiovascular ya que ayudan a regular el colesterol total, HDL, LDL y triglicéridos, hasta a nivel cerebral, ya que el DHA es el ácido graso Omega-3 más abundante en las membranas de nuestras neuronas. Además, brindan beneficios a nivel de la salud ocular, muscular, estética y bienestar general (piel, uñas, pelo) y durante el embarazo en el desarrollo cerebral del feto.`,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--white)",
            borderColor: "var(--red)",
            stroke: "var(--red)"
          }
        },
        {
          title: "Fosfo-<br>lípidos",
          copy: `Tienen muchas funciones en el cuerpo. Son de extrema importancia para el funcionamiento normal de las membranas celulares, le otorgan fluidez para poder incorporar nutrientes (como el EPA y el DHA) a cada célula, participan del metabolismo energético, ayudan a la coagulación de la sangre, tienen acción antioxidante y contribuyen a la solubilidad del colesterol, entre otros beneficios.
          <br>
          <br>
          Además actúan como agentes humectantes superficiales, activo que recubre el exterior del hígado, los pulmones, el tracto gastrointestinal y las células renales.
          <br>
          <br>
          Es importante que todas las células de nuestro cuerpo mantengan una suficiente cantidad de fosfolípidos para garantizar su óptimo funcionamiento.`,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--red)",
            borderColor: "var(--black)"
          }
        },
        {
          title: "COLINA",
          copy: `La colina es un nutriente esencial similar a una vitamina, fundamental para la función normal de las células. Nuestro cuerpo produce cantidades mínimas de colina por lo cual hay que suministrarla a través de la dieta. 
          <br>
          <br>
          Al igual que el DHA, la colina es importante para el desarrollo cerebral. Durante el período de gestación, es imprescindible para el desarrollo del cerebro y la memoria del feto y para reducir el riesgo de defectos en el tubo neural.
          <br>
          <br>
          En el sistema nervioso, la colina se convierte en acetilcolina que es importante para el aprendizaje, la respiración, la memoria, el sueño y el metabolismo muscular.`,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--black)",
            borderColor: "var(--red)",
            stroke: "var(--red)"
          }
        },
        {
          title: "Asta-<br>xantina",
          copy: `
          Es un potente antioxidante y es el responsable del color rojo del aceite de krill.
          <br><br>
          La función de los antioxidantes en nuestro organismo es contrarrestar los efectos perjudiciales de los denominados radicales libres. Estas moléculas son las causantes del estrés oxidativo contribuyendo al envejecimiento celular y a diversos problemas crónicos de salud.`,
          customStyle: {
            color: "var(--white)",
            backgroundColor: "var(--red)",
            borderColor: "var(--white)",
            stroke: "var(--white)"
          }
        }
      ]
    };
  },
  methods: {
    openModal(id) {
      this.modal.title = this.modal_data[id].title;
      this.modal.copy = this.modal_data[id].copy;
      this.modal.customStyle = this.modal_data[id].customStyle;
      this.showModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  height: 100vh;
  background-color: var(--black);

  display: grid;
  grid-template-rows: repeat(6, 1fr);

  @media (min-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "imagenA omega fosfolipidos"
      "colina imagenB astaxantina";
    grid-template-rows: repeat(2, 1fr);
  }

  .caracteristica-item {
    display: grid;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    padding-left: calc(var(--padding-sections) * 3);

    &:not(.empty) {
      cursor: pointer;
    }

    &.imagenA,
    &.imagenB {
      padding: 0;
    }

    .title {
      font-family: "Gotham-Ultra";
      font-size: 4vmax;
      text-transform: uppercase;
      @media (min-width: 480px) {
        font-size: 3vmax;
      }
    }

    picture {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &.imagenA {
      @media (min-width: 480px) {
        grid-area: imagenA;
      }
    }

    &.omega {
      background-color: var(--white);
      color: var(--red);

      @media (min-width: 480px) {
        grid-area: omega;
      }
    }

    &.fosfolipidos {
      background-color: var(--red);
      color: var(--black);

      @media (min-width: 480px) {
        grid-area: fosfolipidos;
      }
    }

    &.colina {
      background-color: var(--black);
      color: var(--red);

      @media (min-width: 480px) {
        grid-area: colina;
        background-color: var(--red);
        color: var(--white);
      }
    }

    &.astaxantina {
      background-color: var(--red);
      color: var(--white);

      @media (min-width: 480px) {
        grid-area: astaxantina;
        background-color: var(--black);
        color: var(--red);
      }
    }

    &.imagenB {
      @media (min-width: 480px) {
        grid-area: imagenB;
      }
    }
  }
}
</style>
