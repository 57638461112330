<template>
  <section id="Contacto">
    <div class="content">
      <h2 class="title">Contacto</h2>
      <form class="form" @submit.prevent="handleSubmit" autocomplete="off">
        <div class="form-item">
          <label for="name">Nombre</label>
          <input type="text" id="name" v-model="form.name" required>
        </div>
        <div class="form-item">
          <label for="lastname">Apellido</label>
          <input type="text" id="lastname" v-model="form.lastname" required>
        </div>
        <div class="form-item">
          <label for="email">Mail</label>
          <input type="email" id="email" v-model="form.email" required>
        </div>
        <div class="form-item">
          <label for="subject">Asunto</label>
          <input type="text" id="subject" v-model="form.subject" required>
        </div>
        <div class="form-item message">
          <label for="message">Consulta</label>
          <textarea name="message" id="message" rows="3" v-model="form.message" required></textarea>
        </div>
        <div class="form-item submit">
          <input type="submit" value="ENVIAR" class="btn">
        </div>
      </form>
    </div>
    <div class="footer">
      <div class="logo-poen">
        <img src="../assets/poen-logo.png" alt="Poen Logo">
      </div>
      <div class="copy">
        <p>Ante cualquier duda, consultá a tu médico y/o farmacéutico.</p>
        <p>© 2019 Laboratorios POEN</p>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";

const API_URL =
  window.location.hostname === "localhost"
    ? "http://diar-api.test/"
    : "https://diar-api.tribaldevelop.com";

export default {
  name: "Contacto",
  data() {
    return {
      form: {
        name: "",
        lastname: "",
        email: "",
        subject: "",
        message: ""
      }
    };
  },
  methods: {
    handleSubmit() {
      let form_data = new FormData();
      let item = this.form;

      for (let key in item) {
        form_data.append(key, item[key]);
      }

      swal.fire({
        type: "info",
        text: "Estamos procesando tu consulta...",
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      });

      axios
        .post(API_URL, form_data, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded"
          }
        })
        .then(res => {
          if (res.data.success) {
            swal.fire({
              type: "success",
              text: res.data.successMessage,
              confirmButtonText: "Volver",
              allowEscapeKey: false,
              allowOutsideClick: false,
              onAfterClose: () => {
                this.clearForm();
              }
            });
          } else {
            swal.fire({
              type: "error",
              allowEscapeKey: false,
              allowOutsideClick: false,
              text: "Ocurrió un error, por favor intente nuevamente más tarde.",
              confirmButtonText: "Volver"
            });
          }
        })
        .catch(error => {
          swal.fire({
            type: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            text: "Ocurrió un error, por favor intente nuevamente más tarde.",
            confirmButtonText: "Volver"
          });
        });
    },
    clearForm() {
      this.form.name = "";
      this.form.lastname = "";
      this.form.email = "";
      this.form.subject = "";
      this.form.message = "";
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  padding: calc(var(--padding-sections) * 3);
  min-height: 100vh;
  background-color: var(--black);
  color: var(--red);
  font-family: "Gotham-Bold";

  @media (min-width: 480px) {
    padding: calc(var(--padding-sections)) 15vmax;
  }

  .title {
    font-family: "Gotham-Ultra";
    text-transform: uppercase;
    font-size: 6.4vmax;

    @media (min-width: 480px) {
      font-size: 4vmax;
    }
  }

  .form {
    display: grid;
    grid-gap: 1vmax;
    margin: 3vmax auto 5vmax;
    width: 100%;

    @media (min-width: 480px) {
      grid-template-columns: repeat(2, 50%);
    }

    &-item {
      @media (min-width: 480px) {
        grid-column: 1;
        display: grid;
        align-items: stretch;
      }

      &.message {
        @media (min-width: 480px) {
          grid-column: 2;
          grid-row: 1 / span 4;

          grid-template-rows: auto 1fr;
        }
      }

      &.submit {
        @media (min-width: 480px) {
          grid-column: 2;
        }
      }

      label {
        font-size: 2vmax;
        width: 100%;
        @media (min-width: 480px) {
          font-size: 1.2vmax;
        }
      }

      input,
      textarea {
        font-size: 2.2vmax;
        padding: 1vmax;
        resize: vertical;
        width: 100%;
        margin-top: calc(var(--padding-sections) * 0.2);
        border-radius: 0;
        appearance: none;
        border: none;
        box-shadow: none;
        outline: none;

        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }

        @media (min-width: 480px) {
          font-size: 1.4vmax;
        }
      }

      .btn {
        background-color: var(--red);
        border: none;
        line-height: 2;
        width: 50%;
        color: var(--white);
        font-size: 2vmax;
        display: block;
        margin: auto;
        border-radius: 0;
        appearance: none;
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;

        @media (min-width: 480px) {
          margin: 0 0 0 auto;
          font-size: 1.4vmax;
        }
      }
    }
  }

  .footer {
    display: grid;
    grid-template-columns: 25% 1fr;
    grid-gap: var(--padding-sections);

    color: var(--white);
    font-size: 1.6vmax;

    @media (min-width: 480px) {
      grid-template-columns: 10% 1fr;
      grid-gap: calc(var(--padding-sections) * 0.6);
      font-size: 1vmax;
    }
  }
}
</style>
