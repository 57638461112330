<template>
  <section id="Chia">
    <div class="content">
      <h2 class="title">Chía,
        <br>Pescado
        <br>y Krill
      </h2>
      <div class="copy">
        <p>El Omega-3 puede obtenerse de fuentes marinas (aceite de pescado o krill) o vegetales (aceite de semillas de chía como el más utilizado).</p>
      </div>
      <div class="pack">
        <img src="../assets/Animacion-caja2.gif" alt>
      </div>
      <div class="lineas-paralelas">
        <img src="../assets/lineas-paralelas.png" alt>
      </div>
    </div>
    <div class="sidebar">
      <div class="sidebar-item" @click="openModal(0)">
        <h3 class="title">
          <span>Aceite de origen marino</span>
          <br>VS
          <br>Aceite de origen vegetal
        </h3>
        <div class="mas-info" id="marino+info">+
          <br>Info
        </div>
      </div>
      <div class="sidebar-item" @click="openModal(1)">
        <h3 class="title">
          Pescado
          <span>
            <br>VS
            <br>KRILL
          </span>
        </h3>
        <div class="mas-info" id="pescado+info">+
          <br>Info
        </div>
      </div>
    </div>

    <modal v-if="showModal" @close="showModal = false" :customStyle="modal.customStyle">
      <h3 slot="header" v-html="modal.title"></h3>
      <p slot="body" v-html="modal.copy"></p>
    </modal>
  </section>
</template>

<script>
import Modal from "../components/Modal";

export default {
  name: "Chia",
  components: {
    Modal
  },
  data() {
    return {
      showModal: false,
      modal: {
        title: "",
        copy: "",
        customStyle: {}
      },
      modal_data: [
        {
          title: `
            Aceite de origen marino
            <br>
            VS Aceite de origen vegetal
            `,
          copy: `
            Omega-3 es una familia de grasas saludables. Las más conocidas son EPA, DHA y ALA. 
            Los aceites obtenidos a partir de semillas como la chía están compuestos por ALA,
            el cual no tiene todas las propiedades beneficiosas que tienen los ácidos grasos EPA y DHA.
            <br><br>
            Para que el ALA sea útil, nuestro cuerpo debe transformarlo en EPA y DHA, que son los ácidos grasos Omega-3 realmente beneficiosos para la salud.
            <br><br>
            La capacidad de nuestro cuerpo de transformar al ALA en EPA y DHA es realmente muy baja (solo 1 – 5%). Aun incorporando a nuestras dietas grandes cantidades de ALA, a través del aceite de chía por ejemplo, no lograremos alcanzar niveles de EPA y DHA efectivos para la salud.
            <br><br>
            Por el contrario, en las fuentes marinas de Omega-3 encontramos directamente EPA y DHA, los ácidos grasos Omega-3 en su forma útil, por lo que el organismo puede aprovecharlos directamente sin necesidad de transformarlos.
            <br><br>
            Esta diferencia en el aprovechamiento en nuestro organismo entre DIAR y el aceite de Chía es de 1 en 12 (1 cápsula de DIAR = 12 cápsulas de Chía).
          `,
          customStyle: {
            color: "var(--red)",
            backgroundColor: "var(--white)",
            borderColor: "var(--red)",
            stroke: "var(--red)"
          }
        },
        {
          title: `
            PESCADO
            <br>
            VS KRILL
          `,
          copy: `
            Estas fuentes de Omega 3, ambas de origen marino, son ricas en EPA y DHA, los ácidos grasos con propiedades benéficas para la salud. Pero, ¿qué las diferencia?
            <br><br>
            Principalmente es su estructura química. A diferencia del pescado, los Omega-3 en el aceite de Krill viajan unidos a fosfolípidos, lo que les confiere una mayor capacidad de absorberse en el intestino, es decir tienen una mayor biodisponibilidad, lo que significa que serán aprovechados más rápido y en mayor cantidad por nuestro cuerpo.
            <br><br>
            DIAR es 2,5 veces más biodisponible que el Omega 3 de aceite de pescado.
            <br><br>
            Además, DIAR aporta fosfolípidos, astaxantina y colina, nutrientes esenciales que no aporta ninguna otra fuente de Omega 3.
          `,
          customStyle: {
            color: "var(--black)",
            backgroundColor: "var(--white)",
            borderColor: "var(--black)"
          }
        }
      ]
    };
  },
  methods: {
    openModal(id) {
      this.modal.title = this.modal_data[id].title;
      this.modal.copy = this.modal_data[id].copy;
      this.modal.customStyle = this.modal_data[id].customStyle;
      this.showModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  background-color: var(--white);
  font-family: "Gotham-Bold";
  min-height: 100vh;
  color: var(--black);

  display: grid;
  justify-content: center;
  align-content: space-between;

  grid-template-areas:
    "content"
    "sidebar";

  @media (min-width: 480px) {
    grid-template-areas:
      "content sidebar"
      "content sidebar";
    grid-gap: 2rem;
  }

  .content {
    grid-area: content;
    padding: calc(var(--padding-sections) * 3);
    padding-bottom: 0;
    position: relative;

    @media (min-width: 480px) {
      padding: calc(var(--padding-sections)) 30vmax;
      padding-right: 0;
    }

    .title {
      font-family: "Gotham-Ultra";
      font-size: 5.6vmax;
      text-transform: uppercase;
      @media (min-width: 480px) {
        font-size: 4vmax;
      }
    }

    .copy {
      font-size: 2.1vmax;
      line-height: 1.3;
      color: var(--red);
      @media (min-width: 480px) {
        font-size: 1.6vmax;
      }
    }

    .lineas-paralelas {
      position: absolute;
      top: 16vmax;
      right: 16vmin;
      width: 5vmax;

      @media (min-width: 480px) {
        right: calc(-5vmax + -2rem);
        top: 14vmax;
      }
    }
  }

  .sidebar {
    grid-area: sidebar;

    @media (min-width: 480px) {
      height: 100%;
      display: grid;
      grid-template-rows: repeat(3, 33.33%);
      grid-template-areas:
        "vacio"
        "middle"
        "last";
    }

    &-item {
      padding-top: var(--padding-sections);
      padding-bottom: var(--padding-sections);
      padding-left: calc(var(--padding-sections) * 3);
      position: relative;
      font-size: 2.6vmax;
      color: var(--white);
      text-transform: uppercase;
      cursor: pointer;

      display: grid;
      justify-content: start;
      align-content: center;
      grid-gap: 1rem;

      @media (min-width: 480px) {
        padding-left: var(--padding-sections);
        padding-right: var(--padding-sections);
        font-size: 1.3vmax;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        background-size: contain;
        background-repeat: no-repeat;

        @media (min-width: 480px) {
          display: none;
        }
      }

      .mas-info {
        display: none;
        text-transform: uppercase;

        @media (min-width: 480px) {
          display: block;
        }
      }

      &:first-child {
        background-color: var(--red);

        @media (min-width: 480px) {
          grid-area: middle;
        }

        .title {
          span {
            color: var(--black);
          }
        }

        &:after {
          background-image: url("../assets/cruz_white.png");
        }
      }
      &:last-child {
        background-color: var(--black);

        @media (min-width: 480px) {
          grid-area: last;
        }

        .title {
          span {
            color: var(--red);
          }
        }

        &:after {
          background-image: url("../assets/cruz_red.png");
        }
      }
    }
  }
}
</style>
