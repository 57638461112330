<template>
  <Slide right width="100">
    <a href="#Diar" @click="smoothScroll('#Diar')">
      <img src="../assets/icon_diar.png" alt="Diar">
      <span>DIAR</span>
    </a>
    <a href="#El-Krill" @click="smoothScroll('#El-Krill')">
      <img src="../assets/icon_krill.png" alt="El Krill">
      <span>EL Krill</span>
    </a>
    <a href="#Chia" @click="smoothScroll('#Chia')">
      <img src="../assets/icon_chia.png" alt="Chia">
      <span>Chía, pescado y krill</span>
    </a>
    <a href="#Beneficios" @click="smoothScroll('#Beneficios')">
      <img src="../assets/icon_beneficios.png" alt="Beneficios">
      <span>Beneficios</span>
    </a>
    <a href="#Contacto" @click="smoothScroll('#Contacto')">
      <img src="../assets/icon_contacto.png" alt="Contacto">
      <span>Contacto</span>
    </a>
  </Slide>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  name: "MenuMobile",
  components: {
    Slide
  },
  methods: {
    smoothScroll(element) {
      document.querySelector(element).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>